.c-header{
    &__image{
        max-width: 100%;
        height: auto;
        display: block;
    }
}
.c-footer{
    &__image{
        max-width: 100%;
        height: auto;
        display: block;
    }
}
.c-cta{
    &__image{
        max-width: 100%;
        height: auto;
        display: block;
    }
}